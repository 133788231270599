// extracted by mini-css-extract-plugin
export var Block = "AwardsBlocks-module--Block--f6b99";
export var Block_Img = "AwardsBlocks-module--Block_Img--a2754";
export var Block_Text = "AwardsBlocks-module--Block_Text--fe119";
export var Blocks = "AwardsBlocks-module--Blocks--be0c9";
export var Button = "AwardsBlocks-module--Button--dae08";
export var DoubleMargin = "AwardsBlocks-module--DoubleMargin--eb68d";
export var Padding = "AwardsBlocks-module--Padding--2012f";
export var ReadMoreButton = "AwardsBlocks-module--ReadMoreButton--59eac";
export var SingleMargin = "AwardsBlocks-module--SingleMargin--c2298";
export var Wrapper = "AwardsBlocks-module--Wrapper--0bc01";