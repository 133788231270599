import React, { useContext } from 'react'
import { AWARD_COUNTIES } from '../../../lib/data/Awards'
import * as ArchiveStyles from './AwardsBlocks.module.scss'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../../lib/utils'

const AwardsBlocks = () => {
  const AwardsContext = useContext(MuddyAwardsContext)

  return (
    <div className={ArchiveStyles.Wrapper}>
      <div className={ArchiveStyles.Blocks}>
        {AWARD_COUNTIES.map(c => (
          <div className={ArchiveStyles.Block} key={c.title}>
            {c.homepagePreviewImage && (
              <div className={ArchiveStyles.Block_Img}>
                <img src={c.homepagePreviewImage ?? ''} alt={''} />
              </div>
            )}
            <div className={ArchiveStyles.Block_Text}>
              <span>{c.title}</span>
              {AwardsContext === MuddyAwardsStates.NOMINATIONS ? (
                <a href={`${c.uri}/nominate`} className={ArchiveStyles.Button}>
                  Nominate
                </a>
              ) : (
                <>
                  {AwardsContext === MuddyAwardsStates.VOTING ? (
                    <>
                      <a
                        href={`${c.uri.replace('/awards', '')}/vote`}
                        className={ArchiveStyles.Button}
                      >
                        Vote
                      </a>
                    </>
                  ) : (
                    <>
                      {AwardsContext === MuddyAwardsStates.WINNERS ? (
                        <a
                          href={`${c.uri}/winners/`}
                          className={ArchiveStyles.Button}
                        >
                          View Winners
                        </a>
                      ) : (
                        <a href={`${c.uri}/`} className={ArchiveStyles.Button}>
                          Find Out More
                        </a>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AwardsBlocks
