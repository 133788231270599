import React, { useState, useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { AdStarBlock, Category, GeneralSettings } from '../../../types'
import { IMenu } from '../../../graphql/entities/Menu'
import TopBanner from '../../../components/Banners/TopBanner/TopBanner'
import Content from '../../../components/Content/Content'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import AwardsHeader from '../../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../../components/PageMeta/PageMeta'
import AwardsBlocks from '../../../components/MuddyAwards/AwardsBlocks'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../../lib/utils'
import Leaderboard from '../../../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsCountiesProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
    }
  }
}

const AwardsCounties = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category
    }
  }
}: AwardsCountiesProps) => {
  const [pageName, setPageName] = useState<string>('Nominate A Business')
  const AwardsContext = useContext(MuddyAwardsContext)

  useEffect(() => {
    if (AwardsContext === MuddyAwardsStates.WINNERS) {
      setPageName('Regional Winners')
    } else if (
      AwardsContext === MuddyAwardsStates.NOMINATIONS_CLOSED ||
      AwardsContext === MuddyAwardsStates.VOTING_CLOSED ||
      AwardsContext === MuddyAwardsStates.NATIONAL_FINALISTS ||
      AwardsContext === MuddyAwardsStates.NATIONAL_WINNERS
    ) {
      setPageName('Counties')
    } else if (AwardsContext === MuddyAwardsStates.VOTING) {
      setPageName('Vote For A Business')
    }
  }, [AwardsContext])

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={`/ ${pageName}`}
            postURI={'/awards/counties'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: pageName }} />
          {AwardsContext === MuddyAwardsStates.WINNERS && (
            <p style={{ textAlign: 'center' }}>The Results Are In!</p>
          )}
          {AwardsContext === MuddyAwardsStates.NATIONAL_WINNERS && (
            <p style={{ textAlign: 'center' }}>
              Check out your regional winners by selecting your county below.
            </p>
          )}
        </Content>
        <AwardsBlocks />
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsCounties
